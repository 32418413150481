import colors from '../constants/colors';

export default function themePalette(theme) {
	return {
		background: {
			default: colors.background,
		},
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
		text: {
			primary: colors.primary,
		},
	};
}
