import { createTheme } from '@mui/material/styles';

import themePalette from './palette';
import themeTypography from './typography';
import componentStyleOverrides from './components';

// A custom theme for this app
const materialTheme = createTheme({
  palette: themePalette(),
  typography: themeTypography(),
  components: componentStyleOverrides(),
  spacing: 10,
});

export default materialTheme;
