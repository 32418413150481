import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as routePaths from "../constants/routes";
import Home from "../scenes/Landing/Home";
import PrivacyPolicy from "../scenes/Policies/PrivacyPolicy";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePaths.ROUTE_HOME} element={<Home />} />
        <Route
          path={routePaths.ROUTE_PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />

        {/* Fallback */}
        <Route
          path="*"
          element={<Navigate replace to={routePaths.ROUTE_HOME} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
