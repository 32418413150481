import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useStyles } from './styles';

const PrivacyPolicy = () => {
	const { classes } = useStyles();
	return (
		<Container maxWidth="md" className={classes.root}>
			<Box className={classes.content}>
				<Typography variant="h3" component="h1" color="primary" paragraph>
					Privacy Policy
				</Typography>

				<Typography variant="h4">1. Information Collection</Typography>
				<Typography variant="body1" paragraph>
					We collect various types of information to provide and improve our services for you:
					<br />• Information you provide directly: When you register on our website, we may collect your name, email address, phone number, and other personal
					information.
					<br />• Information we receive from other sources: For example, if you log in to the site through social networks (Facebook, Google, LinkedIn, etc.), we may
					receive your public information from your profile in these networks.
					<br />• Automatically collected information: We may automatically collect information about your device and behavior on our website using cookies and other
					tracking technologies.
				</Typography>

				<Typography variant="h4">2. Use of Information</Typography>
				<Typography variant="body1" paragraph>
					We use the collected information to:
					<br />• Provide and improve our services.
					<br />• Personalize your experience on our website.
					<br />• Ensure security and prevent fraud.
					<br />• Communicate with you, including sending promotional and informational messages.
				</Typography>

				<Typography variant="h4">3. Sharing Information with Third Parties</Typography>
				<Typography variant="body1" paragraph>
					We do not share your personal information with third parties except in cases where it is necessary to:
					<br />• Provide services on behalf of our company (e.g., payment processing).
					<br />• Comply with legal obligations.
					<br />• Protect the rights, property, or safety of our company, our users, or the public.
				</Typography>

				<Typography variant="h4">4. Data Security</Typography>
				<Typography variant="body1" paragraph>
					We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmitting data over the internet
					or method of electronic storage is 100% secure, so we cannot guarantee absolute security.
				</Typography>

				<Typography variant="h4">5. Your Rights</Typography>
				<Typography variant="body1" paragraph>
					You have the right to access, correct, or delete your personal information that we hold. You can contact us to exercise these rights.
				</Typography>

				<Typography variant="h4">6. Changes to the Privacy Policy</Typography>
				<Typography variant="body1" paragraph>
					We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.{' '}
				</Typography>

				<Typography variant="h4">7. Contact Information</Typography>
				<Typography variant="body1" paragraph>
					If you have any questions or suggestions regarding our privacy policy, please contact us at: gdpr@cv2job.com{' '}
				</Typography>
			</Box>
		</Container>
	);
};

export default PrivacyPolicy;
