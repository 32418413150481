import colors from '../constants/colors';

export default function componentStyleOverrides(theme) {
	return {
		MuiTablePagination: {
			styleOverrides: {
				root: {
					border: 0,
				},
				spacer: {
					display: 'none',
				},
				toolbar: {
					paddingLeft: '0!important',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					fill: colors.inputBorder,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					position: 'relative',
					marginBottom: 30,

					'&:after': {
						content: "''",
						position: 'absolute',
						bottom: 0,
						left: 0,
						width: '100%',
						height: 2,
						backgroundColor: colors.tabsBorderColor,
						zIndex: 0,
					},
				},
				indicator: {
					backgroundColor: colors.tabsBorderColorSelected,
					zIndex: 1,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: colors.tabsTextColor,
					fontSize: '1.2rem',
					fontWeight: 700,
					lineHeight: '1.6rem',
					textTransform: 'uppercase',
					padding: '20px 30px',

					'&.Mui-selected': {
						color: colors.tabsTextColorSelected,
					},
					'&:first-of-type': {
						paddingLeft: 0,
					},
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					fill: 'transparent',
				},
			},
		},
		MuiInputAdornment: {
			styleOverrides: {
				positionEnd: {
					'& .MuiSvgIcon-root': {
						fill: colors.InputAdornmentColor,
					},
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					color: colors.textColorPrimary,
				},
				secondary: {
					color: colors.textColorSecondary,
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				barColorPrimary: {
					backgroundColor: colors.background,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				text: {
					whiteSpace: 'nowrap',
				},
				root: {
					color: 'white',
					fontSize: '1.4rem',
					fontWeight: '500',
					lineHeight: '2.4rem',
					textTransform: 'initial',
					borderRadius: 6,
					paddingLeft: 12,
					paddingRight: 12,
					whiteSpace: 'nowrap',
				},
				contained: {},
				containedPrimary: {
					background: colors.background,
					boxShadow: '0px 8px 22px 0px rgba(74, 58, 255, 0.26)',
					'&:active': {
						boxShadow: 'none',
						background: colors.primaryBtnHoverBg,
					},
					'&:hover': {
						background: colors.primaryBtnHoverBg,
						boxShadow: 'none',
					},
				},
				containedSecondary: {
					color: colors.secondaryBtnColor,
					background: colors.secondaryBtnBg,
					boxShadow: '0px 8px 22px 0px rgba(74, 58, 255, 0.26)',
					'&:hover': {
						background: colors.background,
						color: '#FFF',
					},
					'& .MuiSvgIcon-root': {
						color: 'inherit',
					},
				},
				sizeLarge: {
					fontSize: '1.8rem',
					fontStyle: 'normal',
					fontWeight: 700,
					lineHeight: '2rem',
					borderRadius: 10,
					padding: '14px 30px',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				inputMultiline: {
					overflow: 'hidden',
					resize: 'vertical',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 6,
					borderColor: colors.inputBorder,

					'&.Mui-focused': {
						borderColor: colors.inputBorderFocused,
					},
				},
				input: {
					color: colors.inputText,
					fontSize: '1.4rem',
					fontStyle: 'normal',
					fontWeight: 500,
					lineHeight: '2.4rem',
					letterSpacing: '-0.2px',

					padding: '11px 14px',
				},
			},
		},
		MuiStepper: {
			styleOverrides: {
				root: {
					width: '100%',
					background: 'rgba(84, 31, 243, 0.05)',
				},
			},
		},
		MuiStep: {
			styleOverrides: {
				root: {
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					paddingTop: '22px',
					paddingBottom: '18px',
					'&.active': {
						borderRadius: '20px 20px 0px 0px',
						opacity: 0.9,
						background: '#FFF',
					},
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: {},
				label: {
					fontSize: '1.2rem',
					fontWeight: 700,
					lineHeight: '1.6rem',
					textTransform: 'uppercase',
					color: '#B0ADCB',

					'&.Mui-active': {
						color: colors.background,
						fontWeight: 700,
					},
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					width: 20,
					height: 20,
					color: colors.backgroundSecondary,
					fill: 'currentColor!important',
					'&.Mui-active': {
						color: colors.background,
					},
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 36,
					height: 20,
					padding: 0,
				},
				switchBase: {
					padding: 2,
					'&.Mui-checked': {
						transform: 'translateX(16px)',
					},
				},
				colorPrimary: {
					'&.Mui-checked + .MuiSwitch-track': {
						backgroundColor: colors.background,
						opacity: 1,
					},
					'&.Mui-checked .MuiSwitch-thumb': {
						backgroundColor: '#FFF',
					},
				},
				thumb: {
					width: 16,
					height: 16,
				},
				track: {
					backgroundColor: '#D7DFE9',
					borderRadius: 20,
				},
			},
		},
	};
}
