// for color name mapping see https://www.color-name.com/
export const White = '#FFF';
export const HanPurple = '#541FF3';
export const CetaceanBlue = '#170F49';
export const CadetBlue = '#B0ADCB';
export const DarkBlueGray = '#6F6C90';
export const OuterSpace = '#2D3643';
export const Lavender = '#EEE9FE';
export const DeepMauve = '#F6F4FE';
export const Platinum = '#E6E6E6';
export const ShamrockGreen = '#11A75C';
export const LightGreen = '#1DB4691F';

const colors = {
	background: HanPurple,
	primary: CetaceanBlue,
	secondary: DarkBlueGray,

	// Typography
	disabledText: Platinum,
	readMoreText: HanPurple,
	linkText: HanPurple,

	// Background
	backgroundSecondary: CadetBlue,

	// List
	textColorPrimary: CetaceanBlue,
	textColorSecondary: DarkBlueGray,
	textColorGrey: CadetBlue,
	ListTextColorPurple: HanPurple,

	// Button
	primaryBtnHoverBg: CetaceanBlue,
	secondaryBtnColor: HanPurple,
	secondaryBtnBg: White,
	iconButtonBorder: HanPurple,
	iconWhiteButtonBorder: Lavender,
	iconButtonContainedBg: Lavender,
	percentageBtnColor: ShamrockGreen,
	percentageBtnBg: LightGreen,


	// Form
	inputText: OuterSpace,
	inputBorder: Lavender,
	inputBorderFocused: HanPurple,
	labelRequiredAsteriskColor: HanPurple,
	InputAdornmentColor: DarkBlueGray,
	uploaderBorderColor: Platinum,

	// Table
	tableHeadBg: DeepMauve,
	tableHeadColor: DarkBlueGray,
	tableBorder: Lavender,
	pageNumberColor: CetaceanBlue,
	pageNumberCurrentColor: HanPurple,

	// Tabs
	tabsTextColor: CadetBlue,
	tabsTextColorSelected: HanPurple,
	tabsBorderColor: DeepMauve,
	tabsBorderColorSelected: HanPurple,
};

export default colors;
