import React from "react";
import Routes from "./routes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from './theme';

const App = () => {
  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
};

export default App;
