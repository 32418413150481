import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: "36px 0 54px",
    },
    content: {
      background: "white",
      padding: "30px 46px",
      borderRadius: 16,
      [theme.breakpoints.down("md")]: {
        padding: "20px 26px",
				width: "95%",
				margin: "0 auto"
      },
    },
  };
});
