import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: "36px 0 54px",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    content: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    contentLeft: {
      display: "flex",
      flexDirection: "column",
      flex: "0 42%",
      marginBottom: "4%",
    },
    logoWrapper: {
      [theme.breakpoints.down("md")]: {
        margin: "0 auto 25px",
      },
    },
    subTitleWrapper: {
      display: "flex",
      alignItems: "center",
      flex: "1 1",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        margin: "0 auto",
      },
    },
    subTitle: {
      color: "white",
      fontSize: 35,
      lineHeight: "normal",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        fontSize: 24,
      },
    },

    contentRight: {
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
    },
    iconWrapper: {
      img: {
        maxWidth: 300,
        objectFit: "contain",
        [theme.breakpoints.down("md")]: {
          maxWidth: 200,
        },
      },
    },
  };
});
