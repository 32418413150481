import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Logo from "../../../assets/img/Antosh&Co.svg";
import ComingSoonIcon from "../../../assets/icons/ComingSoon.svg";
import Image from "mui-image";
import { useStyles } from "./styles";

const Home = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.contentLeft}>
          <Box className={classes.logoWrapper}>
            <Image src={Logo} alt="Logo" width={184} fit="contain" />
          </Box>
          <Box className={classes.subTitleWrapper}>
            <Typography className={classes.subTitle}>
              Revamp Your Resume. <br /> Ignite Your Career Jorney
            </Typography>
          </Box>
        </Box>
        <Box className={classes.contentRight}>
          <Box className={classes.iconWrapper}>
            <Image src={ComingSoonIcon} alt="Logo" />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;
