import colors from '../constants/colors';

export default function themeTypography(theme) {
  return {
    fontFamily: "'DM Sans', sans-serif",
    htmlFontSize: 10,
    allVariants: {
      color: colors.primary,
    },
    fontFamilyInter: {
      fontFamily: "'Inter', sans-serif",
    },
    h2: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: '3.5rem',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2.8rem',
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: '2.6rem',
    },
    body1: {
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '2.4rem',
      letterSpacing: '-0.3px',
    },
    body2: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    subtitle1: {
      fontSize: '1.4rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '2rem',
      display: 'inline-block',
    },
    subtitle2: {
      fontSize: '1.2rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '2rem',
      display: 'inline-block',
      letterSpacing: '-0.2px',
    },
  };
}
